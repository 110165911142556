import ms from 'ms'

import type { GradientName } from 'etc/gradients'

// ----- Layout & Color --------------------------------------------------------

export const COLOR = {
  PRIMARY: '#CCCAC2',
  SECONDARY: '#B8B7B2'
} as const

export const BACKGROUND = {
  SIZE: '600vw 600vh'
} as const

/**
 * N.B. "MOBILE" (portrait) is the default, so we don't need to define a
 * breakpoint for it.
 */
export const MEDIA_QUERIES = {
  MOBILE: {
    LANDSCAPE: 'screen and (max-device-width: 812px) and (orientation: landscape)'
  },
  DESKTOP: 'screen and (min-device-width: 813px)',
  FULL_SCREEN: 'all and (display-mode: fullscreen)'
} as const

/**
 * Parameters for the name image.
*/
const NAME_WIDTH = 'clamp(320px, 82vw, 700px)'
const NAME_MARGIN_BOTTOM = 'clamp(28px, 10vw, 56px)'
export const NAME = {
  MARGIN: {
    BOTTOM: {
      MOBILE: NAME_MARGIN_BOTTOM,
      MOBILE_LANDSCAPE: NAME_MARGIN_BOTTOM,
      DESKTOP: NAME_MARGIN_BOTTOM
    }
  },
  WIDTH: {
    MOBILE: NAME_WIDTH,
    MOBILE_LANDSCAPE: NAME_WIDTH,
    DESKTOP: NAME_WIDTH
  }
} as const

/**
 * Parameters for the title image.
*/
const TITLE_WIDTH = 'clamp(240px, 42vw, 380px)'
const TITLE_MARGIN_BOTTOM = NAME_MARGIN_BOTTOM
export const TITLE = {
  MARGIN: {
    BOTTOM: {
      MOBILE: TITLE_MARGIN_BOTTOM,
      MOBILE_LANDSCAPE: TITLE_MARGIN_BOTTOM,
      DESKTOP: TITLE_MARGIN_BOTTOM
    }
  },
  WIDTH: {
    MOBILE: TITLE_WIDTH,
    MOBILE_LANDSCAPE: TITLE_WIDTH,
    DESKTOP: TITLE_WIDTH
  }
} as const

/**
 * Parameters for the icons section.
 */
const ICON_SIZE = 'clamp(32px, 6vw, 42px)'
const ICON_GAP = '42px'
export const ICONS = {
  GAP: {
    MOBILE: ICON_GAP,
    MOBILE_LANDSCAPE: ICON_GAP,
    DESKTOP: ICON_GAP
  },
  SIZE: {
    MOBILE: ICON_SIZE,
    MOBILE_LANDSCAPE: ICON_SIZE,
    DESKTOP: ICON_SIZE
  }
} as const

// ----- Fullscreen ------------------------------------------------------------

/**
 * How long the cursor will remain visible after becoming idle in fullscreen
 * mode.
 */
export const FULLSCREEN_CURSOR_HIDE_DELAY = ms('4s')

// ----- Gradient Animations ---------------------------------------------------

/**
 * Use the same preset on initial page load to provides a consistent experience
 * for those who will not remain on the site long and/or who refresh the page in
 * rapid succession.
 */
export const GRADIENT_INITIAL_PRESET: GradientName = 'Frost'

/**
 * How much time to spend cross-fading between gradients.
 */
export const GRADIENT_TRANSITION_TIME_BASE = ms('128s')

/**
 * How much time to spend holding on each gradient before transitioning.
 */
export const GRADIENT_HOLD_TIME_BASE = ms('64s')

/**
 * Logarithmic scale factor for transition times and hold times.
 */
export const GRADIENT_LOG_SCALE_FACTOR = 35_847.5

/**
 * How often to increment/decrement each gradient's angle by 1 degree.
 */
export const GRADIENT_ROTATION_INTERVAL = ms('1s')