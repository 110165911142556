import ms from 'ms'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import nameSrc from 'assets/name.png'
import titleSrc from 'assets/title.png'
import AnimatedGradient from 'components/AnimatedGradient'
import { CenteredScreenPortal } from 'components/CenteredScreenPortal'
import IconSection from 'components/IconSection'
import { Noise } from 'components/Noise'
import { Particles } from 'components/Particles'
import ReloadPrompt from 'components/ReloadPrompt'
import { ServiceWorkerProvider } from 'contexts/ServiceWorker'
import useWaitForImages from 'hooks/use-wait-for-images'

import classes from './App.css'

export default function App() {
  // Used to set our opacity to 0 until the images we need have finished
  // downloading.
  const imagesReady = useWaitForImages([nameSrc, titleSrc])

  // N.B. `imagesReady` is used to and-gate the fade-out class only to ensure
  // the class is not applied on the first render. Otherwise, the component
  // would render invisible instead of slowly fading out.
  return (
    <React.StrictMode>
      <ServiceWorkerProvider
        updateInterval={ms('5 minutes')}
        updateOnUnload
      >
        <CenteredScreenPortal>
          {/* Gradient */}
          <AnimatedGradient />

          {/* Particles */}
          <Particles />

          {/* Noise */}
          <Noise
            className="fixed inset-0 h-full w-full mix-blend-color-burn pointer-events-none opacity-20"
            fps={24}
            density={0.42}
          />

          {/* Content */}
          <div
            className={twMerge(
              'fixed inset-0 flex flex-col items-center justify-center z-10',
              'transition-opacity duration-[4s] ease',
              imagesReady ? 'opacity-100' : 'opacity-0',
              classes.app
            )}
            data-testid="content"
          >
            <div className="flex flex-col items-center justify-center">
              {/* Name */}
              <img
                className={twMerge('block pointer-events-auto opacity-70', classes.name)}
                alt="Joshua Martin"
                src={nameSrc}
                data-testid="name"
              />

              {/* Title */}
              <img
                className={twMerge('block pointer-events-auto opacity-60', classes.title)}
                alt="Software Engineer"
                src={titleSrc}
                data-testid="title"
              />

              {/* Icons */}
              <IconSection />
            </div>
          </div>
          <ReloadPrompt />
        </CenteredScreenPortal>
      </ServiceWorkerProvider>
    </React.StrictMode>
  )
}